import React, { Component } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useNavigate
} from 'react-router-dom'
import {connect} from 'react-redux'
import ReactGA from 'react-ga'
import Invoice from './components/invoice'
import Health from './components/health'
import NotFound from './components/not-found'

ReactGA.initialize(process.env.REACT_APP_GA_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
    return (
        <div>
              <BrowserRouter history={useNavigate}>
                  <div>
                      <Routes>
                          <Route exact path='/health' element={<Health />} />
                          <Route exact path='/:id' element={<Invoice />} />
                          <Route path='*' element={<NotFound />} />
                      </Routes>
                  </div>
              </BrowserRouter>
        </div>
    )
}

export default App
