
const Loading = () => (
    <div className='container text-center'>
        <br />
        <br />
        <br />
        <br/>
        <h3>Loading</h3>
        <br />
    </div>
)

export default Loading;