import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../images/logo/logo.png';

const InvoiceLayout = ({id, children}) => (
  	<div className="main-content">
        <div className="container-fluid">
            <div className="container">
                <div className="card invoice">
                    <div className="pdd-vertical-5 mrg-horizon-25 border bottom invoice-top">
                    	<img className="img-responsive" src={Logo} alt="Crypto payment" />
                        <h5>Invoice<b className='text-dark'>{id}</b></h5>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    </div>
)


export default InvoiceLayout;
