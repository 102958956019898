import {GET_INVOICE} from '../actions/invoice';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_INVOICE:
			if(action.error) {
				if(action.payload.response) {
					return {status: action.payload.response.status, data: action.payload.response.data, error: true}
				} else {
					return {error: true}
				}
				
			}
			return {status: 200, data: action.payload.data};
		default:
			return state;
	}

}