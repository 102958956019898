import axios from 'axios';

export const GET_INVOICE = 'GET_INVOICE';

export const API_URL = process.env.REACT_APP_API_URL; 

export function getInvoice (id) {
  const dataPayload = axios.get(`${API_URL}/public/payments/${id}`);

	return {
		type: GET_INVOICE,
		payload: dataPayload
	}
}




