import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import{createStore, applyMiddleware} from 'redux';
import ReduxPromise from 'redux-promise';
import './styles/app.scss';
import App from './App';
import reducers from './reducers';

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);
const root = ReactDOM.createRoot(document.getElementById("root"));
export const store = createStoreWithMiddleware(reducers);

root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
