import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useInterval } from 'hooks/useInterval'
import { getInvoice } from 'actions/invoice'
import InvoiceContent from './invoice-content'
import InvoiceLayout from './invoice-layout'
import NotFound from './not-found'
import Loading from './loading'


const Invoice = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const invoice = useSelector(state => state.invoice);
    const [invoiceState, setInvoiceState] = useState('NEW');
    const [id, setId] = useState(location.pathname ? location.pathname.replace('/', '') : '');
    const [loadedInvoiceInfo, setLoadedInvoiceInfo] = useState(false);
    const [redirectTimer, setRedirectTimer] = useState();
    const [isRunning, setIsRunning] = useState(true);

    useInterval(() => {
        handleInterval();
    }, isRunning ? 10000 : null);

    useEffect(() =>  {
	 	document.title = "Pay with Crypto - BitclearPayGate";
        dispatch(getInvoice(id));
        return () => {
            clearTimer();
            clearTimeout(redirectTimer);
            setRedirectTimer();
        }
    }, []);

    useEffect(() =>  {
        let invoiceData = invoice.data;
        if(invoiceData) {
            let status = invoiceData.status;
            let redirects = invoiceData.redirectUrls;
            switch(status) {
                case 'EXPIRED':
                    if(redirects && redirects.expired) {
                        return handleRedirect(redirects, redirects.expired);
                    }
                case 'CONFIRMED':
                    if(redirects && redirects.confirmed) {
                        return handleRedirect(redirects, redirects.confirmed);
                    }
                case 'INVALID':
                    if(redirects && redirects.invalid) {
                        return handleRedirect(redirects, redirects.invalid);
                    }
                case 'UNCONFIRMED':
                    if(redirects && redirects.unconfirmed) {
                        return handleRedirect(redirects, redirects.unconfirmed);
                    }
                default:
                    return
            }
        }
        if(loadedInvoiceInfo === false) {
            if(invoiceData) {
                setLoadedInvoiceInfo(true);
            }
            if(invoice.error) {
                setLoadedInvoiceInfo(true);
            }
        }
    }, [invoice]);

    const clearTimer = () => {
        setIsRunning(false);
    }

    const handleRedirect = (redirects, redirectsValue) => {
        setRedirectTimer(setTimeout(() => {window.location.href = redirectsValue}, 5000));
    }

    const handleInterval = () => {
        let invoiceData = invoice.data;
        if(invoiceData) {
            let status = invoiceData.status;
            if(status === 'EXPIRED' || status === 'INVALID' || status === 'CONFIRMED' || invoice.error) {
                clearTimer();
                return;
            }
            dispatch(getInvoice(id));
        } else {
            clearTimer();
        }
    }
    const copyText = (el) => {
        let textArea = document.createElement("textarea");
        let elem = el.target.closest(".copy-text");
        let inner = elem.innerHTML;
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = elem.previousSibling.querySelector('.copy-value').innerHTML;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        elem.innerHTML = 'Copied';
        setTimeout(function(){ 
            elem.innerHTML = inner;
        }, 2000);
    }
    const getStatusClassname = (el) => {
        switch(el) {
            case 'NEW':
                return 'status info'
            case 'CONFIRMED':
                return 'status online'
            case 'EXPIRED':
                return 'status no-disturb'
            case 'INVALID':
                return 'status away'
            case 'UNDERPAID':
                return 'status wait'
            default:
                return'status'
        }
    }
    if(invoice.error) {
        return <NotFound />
    } else {
        if(invoice.data === undefined) {
            return <Loading />
        }
        return (
            <InvoiceLayout id={id}>
                <InvoiceContent 
                    copyText={copyText}
                    getStatusClassname={getStatusClassname}
                    invoice={invoice.data}
                    id={id}
                />
            </InvoiceLayout>
        )
    }
}

export default Invoice
