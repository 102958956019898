import { useEffect } from 'react'
import Logo from '../images/logo/bitcoin.png';

const NotFound = () => {
    useEffect(() => {
        document.title = "Payment not found - 404";
    }, []);
    return (
        <div className='container text-center'>
            <br />
            <br />
            <br />
            <br />
            <img className="img-responsive img-background" src={Logo} alt="Crypto payment" />
            <h1 className='font-size-70 mrg-top-9vw text-dark'>404</h1>
            <h3 className='font-size-30'>Payment not found</h3>
            <p className='mrg-top-30 font-size-18'>We are sorry but the payment you are looking for does not exist.</p>
            <br />
         </div>
    )
}

export default NotFound