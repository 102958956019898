import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useInterval } from 'hooks/useInterval'
import moment from 'moment'
import PropTypes from 'prop-types'
import momentDurationFormatSetup from 'moment-duration-format'

const InvoiceTimer = ({stateClass}) =>  {
    const invoice = useSelector(state => state.invoice);
    const [expirationTime, setExpirationTime] = useState(moment(invoice.data.expirationTime));
    const [startTime, setStartTime] = useState(expirationTime.diff(invoice.data.time, 'seconds'));
    const [time, setTime] = useState(moment(invoice.data.currentTime));
    const [remainingTime, setRemainingTime] = useState('');
    const [remainingTimeSeconds, setRemainingTimeSeconds] = useState(1);
    const [percentage, setPercentage] = useState(100);
    const [isRunning, setIsRunning] = useState(true);

    let progressClass = 'progress progress-info';
    if (percentage <= 25) {
        progressClass = 'progress progress-danger'
    } else if (percentage <= 50) {
        progressClass = 'progress progress-warning'
    }

    useInterval(() => {
        handleTimer();
    }, isRunning ? 1000 : null);

    useEffect(() => {
        setExpirationTime(moment(invoice.data.expirationTime));
        setStartTime(expirationTime.diff(invoice.data.time, 'seconds'));

    }, [invoice]);

    const handleTimer = () => {
        let status = invoice.data.status;
        if(remainingTimeSeconds <= 0) {
            setIsRunning(false);
            return;
        }
        if(status === 'EXPIRED' || status === 'INVALID') {
            setIsRunning(false);
            return;
        }
        let timeExp = expirationTime.diff(time, 'seconds');
        let remainingTimeVal = moment.duration(timeExp, "seconds").format();
        let timePercentage = (timeExp / startTime) * 100;
        setTime(moment(time).add(1, 'second'));
        setRemainingTime(remainingTimeVal);
        setRemainingTimeSeconds(timeExp);
        setPercentage(timePercentage || 0);
    }
    return (
        <div>
            <div className='timer-row mrg-btm-20'>
                <div className={progressClass}>
                    <div className='progress-bar' role='progressbar' aria-valuemin='0' aria-valuemax='100' style={{'width': percentage + '%'}}>
                    </div>
                </div>
            </div>
            <div className='row pdd-btm-15'>
                <div className='col-sm-6 status-container'>
                    <span className='status-label'>Current status:</span>
                    <div className='relative status-el'>
                        <span className={stateClass}> </span>
                        <span className='pdd-left-20 font-size-16 status-name'><b className='text-dark'>{invoice.data.status}</b></span>
                    </div>
                </div>
                <div className='col-sm-6 text-right status-time'>
                    <span className='timer-text'>Valid for: <b className='time text-dark'>{remainingTime} {remainingTimeSeconds > 0 && 'min'}</b></span>
                </div>
            </div>
        </div>
    )
}

InvoiceTimer.propTypes = {
    stateClass: PropTypes.string.isRequired
}

export default InvoiceTimer;