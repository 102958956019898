import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InvoiceBody from './invoice-body';
import InvoiceFooter from './invoice-footer';
import InvoiceTimer from './invoice-timer';
import QRCode from 'qrcode.react';
import { EXPLORERS } from '../helpers/block_explorers';

let transactions = [
    {
        "transactionId": "0x6e4ab8014670083e3c71cb11fe58eccea6f63c761229315aed6132d6b20d886d",
        "paidTransferAmount": 0.18800526,
        "priceAmount": 1000,
        "exchangeRate": 5319.00011733,
        "observedTime": "2023-04-25T08:04:06.894941238",
        "acceptedTime": "2023-04-25T08:04:06.894960567",
        "state": "ACCEPTED"
    }
]

const InvoiceMessage = ({style, header, message, icon, redirects, redirectsValue, invoice}) => (
        <div>
            <InvoiceBody>
                <div className='text-center'>
                    <h3 className={style}><b>{header}</b></h3>
                    <p>{message}</p>
                    <div className={style}>
                       <i className={icon}></i>
                    </div>
                    {redirects && redirectsValue &&
                        <a href={redirectsValue} className='btn btn-default'>Back to shop</a>
                    }
                </div>
            </InvoiceBody>                  
            <InvoiceFooter cancel={false}>
                <InvoiceTransactions invoice={invoice} />
            </InvoiceFooter>
        </div>
)

const InvoiceTransactions = ({invoice}) => (
    <>
        {(invoice && invoice.transactions && invoice.transactions.length) ? (
         <div>
             <div className='border bottom'></div>
             <div className='row pdd-btm-20 pdd-top-20'>
                 <div className='col-md-6'>
                     <span className='status-label'>Incoming payments:</span>
                 </div>
                 {invoice.transactions.map(function(val,i){
                     return (
                         <div className={i === 0 ? 'col-md-6 text-right transaction' : 'col-sm-12 text-right transaction'}>
                            <span>{moment(val.time).format('DD.MM.YY HH:mm:ss')}<b className='text-dark mrg-left-10'>{val.coinAmount < 0.000001 ? val.coinAmount.toFixed(8) : val.coinAmount} {invoice.coinCurrency}</b>
                            </span>
                            <span className='mrg-left-10'>
                            {invoice.blockchain && EXPLORERS[invoice.blockchain] &&
                                <a href={EXPLORERS[invoice.blockchain] + val.txid.split(":")[1] } target='_blank' rel="noopener noreferrer">Preview</a>
                            }
                            </span>
                         </div>
                     )
                 })}
             </div>
         </div>
         ):(<></>)}
    </>
)

const InvoiceContent = (props) => {
    let invoice = props.invoice;
    let stateClass = props.getStatusClassname(invoice.status);
    let redirects = invoice.redirectUrls;
    switch(invoice.status) {
        case 'EXPIRED':
            return (
                <div>
                    <InvoiceMessage
                        header='This invoice has expired'
                        message='We didn&apos;t receive Your payment during invoice validity.'
                        style='text-danger'
                        icon='invoice-icon ei ei-unchecked-boxed'
                        redirects={redirects}
                        invoice={invoice}
                        redirectsValue={redirects ? redirects.expired : undefined}
                    />
                </div>
            )
        case 'INVALID':
            return (
                <div>
                    <InvoiceMessage
                        header='Your payment is invalid'
                        message='Some error occured while processing Your payment. Please contact Your shop for more details.'
                        style='text-warning'
                        icon='invoice-icon ei ei-minus-boxed'
                        redirects={redirects}
                        invoice={invoice}
                        redirectsValue={redirects ? redirects.invalid : undefined}
                    />
                </div>
            )
        case 'UNCONFIRMED':
            return (
                <div>
                    <InvoiceMessage
                        header='Your payment is pending'
                        message='We&apos;re waiting for Your payment confirmation'
                        style='text-dark'
                        icon='invoice-icon ei ei-pause-1'
                        redirects={redirects}
                        invoice={invoice}
                        redirectsValue={redirects ? redirects.unconfirmed : undefined}
                    />
                </div>
            )
        case 'CONFIRMED':
            return (
                <div>
                    <InvoiceMessage
                        header='Payment received'
                        message=''
                        style='text-success'
                        icon='invoice-icon ei ei-checked-box'
                        redirects={redirects}
                        invoice={invoice}
                        redirectsValue={redirects ? redirects.confirmed : undefined}
                    />
                </div>
            )
        default:
            return (
                <div>
                    {invoice.requestedAmount &&
                        <span className='aside-text'>Invoice amount: <b>{invoice.requestedAmount} {invoice.requestedCurrency}</b></span>
                    }
                    <InvoiceBody>

                        <div className='text-main text-center'>
                            <div className='text-dark text-uppercase inline-block'>To pay:</div>
                            <div className='copy-wrapper'>
                                <h3 className='invoice-values'><b className='copy-value'>{invoice.remainingToPayCoin < 0.000001 ? invoice.remainingToPayCoin.toFixed(8) : invoice.remainingToPayCoin}</b><b className='unselectable' unselectable="on"> {invoice.coinCurrency}</b></h3> 
                                <button className='copy-text btn btn-default' onClick={props.copyText}><i className='fa fa-scissors'></i></button>
                            </div>
                            <div className='text-dark text-uppercase inline-block'>Address:</div>
                            <div className='copy-wrapper'>
                                <h3 className='no-wrap invoice-values'><b className='copy-value'>{invoice.address}</b></h3>
                                <button className='copy-text btn btn-default' onClick={props.copyText}><i className='fa fa-scissors'></i></button>
                            </div>
                         
                        </div>
                        <div className='qr-code text-center'>
                            <QRCode size={270} value={invoice.paymentUri ? invoice.paymentUri : invoice.address} />
                        </div>
                        <div className='text-center'>
                            <br />
                            <div>Send given amount of Coin to the address above, scan QR code in Your wallet app</div>
                            {invoice.paymentUri ? (
                                <>
                                    <p className="subtitle fancy"><span>or</span></p>
                                    <br />
                                    <div className="mrg-btm--20"><a className='btn btn-primary' href={invoice.paymentUri}>Click to pay with your Coin wallet</a></div>
                                    <br />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className='qr-code text-center qr-mobile'>
                            <QRCode size={270} value={invoice.paymentUri} />
                        </div>
                    </InvoiceBody>
                    <InvoiceFooter>
                        <InvoiceTimer progressClass={props.progressClass} stateClass={stateClass} invoice={invoice} />
                        <InvoiceTransactions invoice={invoice} />
                     </InvoiceFooter>
                </div>
        )
    }

}
InvoiceMessage.propTypes = {
    header: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    redirects: PropTypes.string,
    redirectsValue: PropTypes.string,
    style: PropTypes.string.isRequired, 
}
InvoiceContent.propTypes = {
    copyText: PropTypes.func.isRequired,
    getStatusClassname: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    invoice: PropTypes.object.isRequired,
    progressClass: PropTypes.string
}

export default InvoiceContent;