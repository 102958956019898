
const InvoiceBody = ({children}) => (
		<div className="card-body">
            <div className="pdd-horizon-30">
                <div className="row mrg-btm-15">
                    <div className="col-md-12">
                        <div className="row mrg-top-30">
                            <div className="col-md-12">
                                {children}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
)

export default InvoiceBody;
