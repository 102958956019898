
const InvoiceFooter = ({children}) => (
	<div className="pdd-vertical-15 pdd-horizon-25 invoice-bottom">
    	<div>
    		{children}
    	</div>
    </div>
)

export default InvoiceFooter;
